<template>
  <div
    class="promotion-bar"
  >
    <div class="promotion-bar__content">
      <h5 class="promotion-bar__title">
        {{ headline }}
      </h5>

      <p>
        {{ description }}

        <a
          v-if="href"
          :href="href"
          target="_blank"
          data-testid="promotion-element-learn-more"
          class="promotion-bar__link"
          @click="onClickElement('blog')"
        >
          {{ $t('layout.header.promotion_element.learn_more') }}
        </a>
      </p>
    </div>

    <div class="promotion-bar__action">
      <ButtonClose
        data-testid="promotion-element-close"
        @click="onClickElement('close')"
      />
    </div>
  </div>
</template>

<script>
import { updateCrmFeatureFlag } from '@client-shared/api/users.api.js'
import { stringValidation } from '@client-shared/utils/prop-validation'

import ButtonClose from '@/components/ButtonClose.vue'
import constants from '@/config/constants'

export default {
  components: {
    ButtonClose,
  },

  props: {
    headline: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    href: {
      type: String,
      default: null,
    },

    crmProperty: {
      type: String,
      required: true,

      validator (value) {
        const validValues = Object.values(constants.CRM_FEATURE_FLAGS)

        return stringValidation({
          value,
          validValues,
          propKey: 'crmProperty',
        })
      },
    },
  },

  data () {
    const validStates = Object.freeze({
      INITIAL: 1,
      SUBMIT_PENDING: 2,
      SUBMIT_ERROR: 3,
    })

    return {
      validStates,
      state: validStates.INITIAL,
      promotionElementVisibleClassName: 'show-promotion-bar',
    }
  },

  created () {
    this.$store.commit('layout/ADD_HTML_CLASS', this.promotionElementVisibleClassName)
  },

  methods: {
    async onClickElement (type) {
      if (this.state === this.validStates.SUBMIT_PENDING) {
        return
      }

      this.$ga.event({ eventCategory: 'Promotion element', eventAction: `${type} link | click`, eventLabel: this.crmProperty })

      this.state = this.validStates.SUBMIT_PENDING

      try {
        await updateCrmFeatureFlag({
          axios: this.$axios,
          user: this.$auth.user,
          propertyName: this.crmProperty,
          newValue: true,
        })

        this.$auth.fetchUser()
        this.$store.commit('layout/REMOVE_HTML_CLASS', this.promotionElementVisibleClassName)
      } catch (err) {
        // NUXT TODO: Define if we want to show error messages in this case
        this.state = this.validStates.SUBMIT_ERROR
      }
    },
  },
}
</script>
